body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  /* position:absolute; */
  /* top: 80px;
  left: 250px;
  bottom: 0;
  right: 0; */
  border-radius: 8px;
  width: 100%;
  height: 97%;
  box-shadow: 0px 0px 4px 0px rgba(0, 7, 22, 0.14), 0px 1px 14px 0px rgba(65, 77, 92, 0.20);
  border-width: 0;
}
.css-jn01mi{
  margin-top: 0 !important;
}
.css-y1gt6f{
  margin-top: 0 !important;
}
.css-1o3oomb{
  margin-top: 0 !important;
}
.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title{background:#ffff!important}
.apexcharts-menu-item.exportPNG,.apexcharts-menu-item.exportSVG{display:none}
@media (max-device-width: 1024px) {
  .css-staguu-MuiDrawer-docked {
    width: 0 !important;
  }
  .css-ofv0ci{
    margin-left: 0 !important;
  }
  .css-ntbaay{
    width: 0 !important;
  }
}
@media (max-device-width: 750px)  {
  .css-staguu-MuiDrawer-docked {
    width: 0 !important;
  }
  .css-ofv0ci{
    margin-left: 0 !important;
  }
  .css-ntbaay{
    width: 0 !important;
  }
}
@media (device-width: 912px) or (device-width: 1180px) {
  .MuiDrawer-docked {
    width: 0 !important;
  }
  .css-ofv0ci{
    margin-left: 0 !important;
  }
  .MuiDrawer-docked{
    width: 0 !important;
  }
  .css-ntbaay{
    width: 0 !important;
  }

}
@media (hover:none), (pointer:coarse) {
  .MuiDrawer-docked {
    width: 0 !important;
  }
  .css-ofv0ci{
    margin-left: 0 !important;
  }
  .css-10qgjif-MuiDrawer-docked{
    width: 0 !important;
  }
  .css-ntbaay{
    width: 0 !important;
  }

}
